export const aspectRatio = (width, height) => {
  const paddingTop = (height / width) * 100;

  return `
    position: relative;

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ${paddingTop}%;
    }
  `;
};
