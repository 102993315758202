const baseAPIUrl = process.env.NEXT_PUBLIC_API_URL;

export default {
  system: {
    get: `${baseAPIUrl}/system/`,
  },
  tags: {
    list: `${baseAPIUrl}/tags/`,
  },
  files: {
    listAll: `${baseAPIUrl}/files/`,
    paginatedList: (tagId = ':tagId') => `${baseAPIUrl}/tags/${tagId}/paginate_files/`,
    item: (slug = ':slug') => `${baseAPIUrl}/files/${slug}/`,
    closestFiles: (fileId = ':fileId', tagId = ':tagId') => `${baseAPIUrl}/closest-file/${fileId}/tag/${tagId}/`,
  },
};
