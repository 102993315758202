import styled from 'styled-components';

import media from '../../common/styles/media';

const S = {};

S.Container = styled.div`
  padding-bottom: 100px;
  overflow: hidden;

  @media ${media.mobile} {
    padding-bottom: 140px;
  }  
`;

S.Grid = styled.div`
  display: grid;

  @media ${media.mobile} {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 0 !important;
    padding: 0 !important;  
  }
`;

export default S;
