import React, { useState, useEffect, useRef, useCallback } from 'react';
import NextLink from 'next/link'
import { useIntersectionObserver } from 'usehooks-ts'

import { ISystem } from '../../interfaces/system';
import { IFile } from '../../interfaces/file';

import { formatDate } from '../../common/date';

import { zIndex } from '../../common/styles/const';

import Tag from '../Tag';

import localIcons from './icons';

import S from './styles';

// images resize config from backend
// we take «h» property and use it in srcSet attribute as «w<size>»
// parameter and use key (w650) for url construction.
//
// img_resolutions = {
//   'w285': {'h': 138, 'crop': 'center', 'quality': 85},
//   'w427': {'h': 207, 'crop': 'center', 'quality': 85},
//   'w640': {'h': 311, 'crop': 'center', 'quality': 85},
//   'w960': {'h': 466, 'crop': 'center', 'quality': 85},
//   'w1440': { 'h': 700, 'crop': 'center', 'quality': 85},
//   'w2160': { 'h': 1050, 'crop': 'center', 'quality': 85},
// }

// video first fram resize
// frame_resolutions = {
//   'w285': {'h': 138, 'crop': 'center', 'quality': 85},
//   'w427': {'h': 207, 'crop': 'center', 'quality': 85},
//   'w640': {'h': 311, 'crop': 'center', 'quality': 85},
//   'w960': {'h': 466, 'crop': 'center', 'quality': 85},
//   'w1440': { 'h': 700, 'crop': 'center', 'quality': 85},
// }

const CURSOR_OFFSET = 23;

function getInfoCoordinates(props: {
  screenX: number,
  offsetX: number,
  offsetY: number,
  containerRef: any,
  ref: any,
  infoRef: any,
  // isChangeInfoAlignment: boolean,
}) {
  const {
    screenX,
    offsetX,
    offsetY,
    containerRef,
    ref,
    infoRef,
    // isChangeInfoAlignment
  } = props;

  const imgOffsetX = (containerRef.current.offsetWidth - ref.current.offsetWidth) / 2;
  const imgOffsetY = (containerRef.current.offsetHeight - ref.current.offsetHeight) / 2;
  
  let x;
  let y;

  x = offsetX + imgOffsetX - CURSOR_OFFSET;
  y = offsetY + imgOffsetY + CURSOR_OFFSET;

  // let isClipped = false;

  // if (screenX - CURSOR_OFFSET + infoRef.current.offsetWidth > window.innerWidth - 20) {
  //   isClipped = true;
  // }

  // isClipped = isChangeInfoAlignment;

  // if (isClipped) {
  //   x = offsetX + imgOffsetX + CURSOR_OFFSET;  
  // }

  return {
    x,
    y,
    // isClipped
  }
}

interface IProps {
  index: number,
  customCursorController: any,
  isMobileViewport: boolean,
  system: ISystem,
  file: IFile,
  // isScrolling: boolean,
  // resetIsScrolling: Function,
  setCurrentFile: Function,
  setCurrentlyHoveredFile: Function,
  resetIsScrolling: Function,
}

const FilePreview = (props: IProps) => {
  const {
    customCursorController,
    isMobileViewport,
    index,
    system,
    file,
    // isScrolling,
    resetIsScrolling,
    setCurrentlyHoveredFile,
  } = props;

  const containerRef = useRef(null);
  const previewRef = useRef(null);
  const imageRef = useRef(null);

  const tags = file?.tags.filter((t) => {
    return !t.isSystemTag;
  });

  const isImage = file.fileType === 'image';
  const isVideo = file.fileType === 'video';
  const isAudio = file.fileType === 'audio';
  const hasTags = tags && file.tags.length;

  let previewImageSrc;
  let previewImageNaturalWidth; 
  let previewImageNaturalHeight;

  let srcSetAttr;

  // images resize config from backend
  // we take «h» property and use it in srcSet attribute as «w<size>»
  // parameter and use key (w650) for url construction.
  //
  // img_resolutions = {
  //   'w285': {'h': 138, 'crop': 'center', 'quality': 85},
  //   'w427': {'h': 207, 'crop': 'center', 'quality': 85},
  //   'w640': {'h': 311, 'crop': 'center', 'quality': 85},
  //   'w960': {'h': 466, 'crop': 'center', 'quality': 85},
  //   'w1440': { 'h': 700, 'crop': 'center', 'quality': 85},
  //   'w2160': { 'h': 1050, 'crop': 'center', 'quality': 85},
  // }

  // video first fram resize
  // frame_resolutions = {
  //   'w285': {'h': 138, 'crop': 'center', 'quality': 85},
  //   'w427': {'h': 207, 'crop': 'center', 'quality': 85},
  //   'w640': {'h': 311, 'crop': 'center', 'quality': 85},
  //   'w960': {'h': 466, 'crop': 'center', 'quality': 85},
  //   'w1440': { 'h': 700, 'crop': 'center', 'quality': 85},
  // }

  if (isImage) {
    previewImageSrc = file.image?.w960Url;
    srcSetAttr = `${file.image?.w960Url} 466w, ${file.image?.w1440Url} 700w, ${file.image?.w2160Url} 1050w`;    
    previewImageNaturalWidth = file.imageWidth
    previewImageNaturalHeight = file.imageHeight;
  } else if (isVideo) {
    previewImageSrc = file.firstFrameVideo?.w960Url;
    srcSetAttr = `${file.firstFrameVideo?.w960Url} 466w, ${file.firstFrameVideo?.w1440Url} 700w`;

    previewImageNaturalWidth = file.firstFrameVideoWidth
    previewImageNaturalHeight = file.firstFrameVideoHeight; 
  }

  if (previewImageSrc) {
    previewImageSrc = previewImageSrc;
  }
  
  let previewFlexDirection = 'row';
  let previewImageWidth: string | number;
  let previewImageHeight: string | number;

  if (previewImageNaturalWidth > previewImageNaturalHeight) {
    previewImageWidth = '100%';
    previewImageHeight = 'auto';
  } else if (previewImageNaturalWidth < previewImageNaturalHeight) {
    previewImageWidth = 'auto';
    previewImageHeight = '100%';
    previewFlexDirection = 'column';
  } else if (previewImageNaturalWidth === previewImageNaturalHeight) {
    previewImageWidth = '100%';
    previewImageHeight = '100%';
  }

  const [isShowInfo, setIsShowInfo] = useState(false);
  const [infoX, setInfoX] = useState<string | number>(null);
  const [infoY, setInfoY] = useState<string | number>(null);
  // const [isInfoClippedX, setIsInfoClippedX] = useState<boolean>(false);
  const [hoverZIndex, setHoverZIndex] = useState<number>(null);

  let margin = '0';

  if (system.margins && !!system.margins.length) {
    if (system.margins.indexOf(' ') > - 1) {
      const marginsArray = system.margins.split(' '); 
      
      margin = '';
      
      marginsArray.forEach((m, i) => {
        margin += m + 'px';

        if (i + 1 < marginsArray.length) {
          margin += ' ';  
        }
      });
    } else {
      margin = system.margins + 'px';
    }
  }

  function onMouseEnter(e) {
    let ref: any;

    setCurrentlyHoveredFile(file);

    // if (isAudio) {
    //   ref = previewRef;
    // } else {
    //   ref = imageRef
    // };

    resetIsScrolling();             
    // setIsShowInfo(true);
    // setHoverZIndex(zIndex.filePreviewHover);

    // const coordinates = getInfoCoordinates({
    //   screenX: e.nativeEvent.screenX,
    //   offsetX: e.nativeEvent.offsetX, 
    //   offsetY: e.nativeEvent.offsetY,
    //   containerRef,
    //   ref,
    //   infoRef,
    //   // isChangeInfoAlignment,
    // });

    // setInfoX(`${coordinates.x}px`);
    // setInfoY(`${coordinates.y}px`);
    // // setIsInfoClippedX(coordinates.isClipped);
  }

  function onMouseMove(e) {
    let ref: any;

    // if (isAudio) {
    //   ref = previewRef;
    // } else {
    //   ref = imageRef
    // };
    
    resetIsScrolling(); 

    setCurrentlyHoveredFile(file);

    // const coordinates = getInfoCoordinates({
    //   screenX: e.nativeEvent.screenX,
    //   offsetX: e.nativeEvent.offsetX, 
    //   offsetY: e.nativeEvent.offsetY,
    //   containerRef,
    //   ref,
    //   infoRef,
    //   // isChangeInfoAlignment
    // });
    
    // setInfoX(`${coordinates.x}px`);
    // setInfoY(`${coordinates.y}px`);
    // // setIsInfoClippedX(coordinates.isClipped);
  };

  function onMouseLeave() {
    setCurrentlyHoveredFile(null);

    // setIsShowInfo(false);
    // setHoverZIndex(null);
  }


  return (
    <S.Container
      ref={containerRef}
      style={{
        zIndex: hoverZIndex,
      }}
    >
      <S.MarginsWrapper
        style={{
          padding: margin,
        }}
      >
        <S.MarginsInner>
          <S.Content
            style={{
              padding: `${system.paddings || system.paddings === 0 ? system.paddings + 'px' : '50px'}`
            }}
          >
            <S.Preview
              ref={previewRef} 
              // isScrolling={isScrolling}
              isAudio={isAudio}
              style={{
                flexDirection: previewFlexDirection
              }}
              onMouseEnter={(e) => {
                if (isAudio) {
                  onMouseEnter(e);
                }
              }}
              onMouseLeave={(e) => {
                if (isAudio) {
                  onMouseLeave();
                }
              }}
              onMouseMove={(e) => {
                if (isAudio) {
                  onMouseMove(e);
                }
              }}
            >
              <NextLink
                href={'/' + file.slugName}
                onClick={() => {
                  customCursorController.disable().unhideTrueCursor();
                }}
              >
                {(isImage || isVideo) && <img
                  ref={imageRef}
                  src={previewImageSrc}
                  srcSet={srcSetAttr}
                  style={{
                    width: previewImageWidth,
                    height: previewImageHeight
                  }}
                  alt={file.name}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onMouseMove={onMouseMove}
                />}

                {isAudio &&
                  <S.AudioTrack>
                    <S.AudioTrackIcon>
                      {localIcons.audioTrack}
                    </S.AudioTrackIcon>
                    <S.AudioTrackIconMobile>
                      {localIcons.audioTrackMobile}
                    </S.AudioTrackIconMobile>
                  </S.AudioTrack>
                }

                {(isAudio || isVideo) && <S.Duration
                  isAudio={isAudio}
                  isVideo={isVideo}
                >
                  {file.fileDuration}
                </S.Duration>}  
              </NextLink>
            </S.Preview>

            {/* <S.Info
              ref={infoRef}
              style={{
                top: infoY,
                left: infoX
              }}
              // isClipped={isInfoClippedX}
              isShow={isShowInfo && !isScrolling}
            >
              <S.Name>{file.name}</S.Name>
              <S.Date>{formatDate(file.date)}</S.Date>
              {hasTags && <S.Tags>{tags.map((tag, i) => {
                return (
                  <Tag
                    key={i} 
                    tag={tag}
                    isGrayText
                />
              );
              })}</S.Tags>}
            </S.Info> */}
          </S.Content>  
        </S.MarginsInner>
      </S.MarginsWrapper>
    </S.Container>
  )
};

export default FilePreview;
