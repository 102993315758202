import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

const isBrowser = typeof window !== 'undefined';

function getScrollPosition({ ref, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = ref ? ref.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export function useScrollPosition(
  effect, deps = [], ref = false, useWindow = false, timeout = 50,
) {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ ref, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return;
    }

    const handleScroll = () => {
      if (timeout) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, timeout);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    // eslint-disable-next-line
    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}

// useScrollPosition.defaultProps = {
//   deps: [],
//   element: false,
//   useWindow: false,
//   timeout: 50,
// };
