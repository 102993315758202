import React, { useEffect, useCallback } from 'react';

import { ISystem } from '../../interfaces/system';
import { IFile } from '../../interfaces/file';

import FilePreview from '../FilePreview';

import S from './styles';

const DEFAULT_COLUMNS_COUNT = 4;

interface IProps {
  customCursorController: any,
  isMobileViewport: boolean,
  system: ISystem,
  files: Array<IFile>,
  setCurrentFile: Function,
  setCurrentlyHoveredFile: Function,
  resetIsScrolling: Function,
  customColumnsCount: number,
  setCustomColumnsCount: Function
}

const FilesGrid = (props: IProps) => {
  const {
    customCursorController,
    isMobileViewport,
    system,
    files,
    setCurrentFile,
    setCurrentlyHoveredFile,
    resetIsScrolling,
    customColumnsCount,
    setCustomColumnsCount
  } = props;

  const onKeyDown = useCallback((e) => { 
    const isCmdPlusPressed = e.metaKey && e.keyCode === 187;
    const isCmdMinusPressed = e.metaKey && e.keyCode === 189;

    if (isCmdPlusPressed || isCmdMinusPressed) {
      e.preventDefault();

      let columnsCount = customColumnsCount || system.columns || DEFAULT_COLUMNS_COUNT;
      
      if (isCmdPlusPressed) {
        if (columnsCount > 1) {
          columnsCount -= 1;
        }  
      } else if (isCmdMinusPressed) {
        if (columnsCount < 10) {
          columnsCount += 1;
        }
      }

      setCustomColumnsCount(columnsCount);
    }
  }, [customColumnsCount, setCustomColumnsCount])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown]);

  return (
    <S.Container>
      <S.Grid
        style={{
          gridTemplateColumns: `repeat(${customColumnsCount ? customColumnsCount : system.columns || DEFAULT_COLUMNS_COUNT}, 1fr)`,
        }}
      >
        {files.map((file, i) => {
          return (
            <FilePreview
              index={i}
              key={i} 
              customCursorController={customCursorController}
              isMobileViewport={isMobileViewport}
              system={system} 
              file={file}
              // isScrolling={isScrolling}
              // resetIsScrolling={resetIsScrolling}
              setCurrentFile={setCurrentFile}
              setCurrentlyHoveredFile={setCurrentlyHoveredFile}
              resetIsScrolling={resetIsScrolling}
            />
          )
        })}
      </S.Grid>
    </S.Container>
  )
};

export default FilesGrid;
