import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';

import useScrollPosition from '../../common/useScrollPosition';

import { ISystem } from '../../interfaces/system';
import { ITag } from '../../interfaces/tag';
import { IFile } from '../../interfaces/file';

import apiUrls from '../../common/apiUrls';

import FilesGrid from '../FilesGrid';

import S from './styles';

interface IProps {
  customCursorController: any,
  isMobileViewport: boolean,
  system: ISystem,
  currentTag: ITag,
  setCurrentTag: Function,
  tags: Array<ITag>,
  files: Array<IFile>,
  setFiles: Function,
  nextFilePageApiUrl: string,
  setNextFilePageApiUrl: Function,
  setCurrentFile: Function,
  setCurrentClosestFiles: Function,
  loadingFilesApiUrl: string,
  setLoadingFilesApiUrl: Function,
  setCurrentlyHoveredFile: Function,
  resetIsScrolling: Function,
  customColumnsCount: number,
  setCustomColumnsCount: Function
}

const TagIndex = (props: IProps) => {
  const {
    customCursorController,
    isMobileViewport,
    system,
    currentTag,
    setCurrentTag,
    tags,
    files,
    setFiles,
    nextFilePageApiUrl,
    setNextFilePageApiUrl,
    setCurrentFile,
    setCurrentClosestFiles,
    loadingFilesApiUrl,
    setLoadingFilesApiUrl,
    setCurrentlyHoveredFile,
    resetIsScrolling,
    customColumnsCount,
    setCustomColumnsCount
  } = props;

  const router = useRouter();
  let tagSlug: string | string[] = router.query.tagOrFileSlug || '';
  
  let currentTagSlug;
  
  let routeChanged = false;

  if (currentTag) {
    currentTagSlug = currentTag.slug;
    
    if (!tagSlug || !tagSlug.length) {
      // on index page
      // there is no tag slug in url

      if (currentTag.isStartTag) {
        currentTagSlug = '';
      }
    }
    
    routeChanged = tagSlug !== currentTagSlug;
  }

  const isLoading = !system || !tags || !currentTag || routeChanged;

  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(null);
  const [isCustomCursorEnabled, setIsCustomCursorEnabled] = useState<boolean>(null);
  const [isRenderedTimeout, setIsRenderedTimeout] = useState<boolean>(null);

  useEffect(() => {
    setTimeout(() => {
      setIsRenderedTimeout(true);
    }, 200);
  }, []);
  
  // Get initial files
  useEffect(() => {
    // reset file page state
    setCurrentFile(null);
    setCurrentClosestFiles(null);

    if (currentTag && tags) {
      if (routeChanged) {

        setFiles(null);

        if (tagSlug === '') {
          setCurrentTag(system.startPage)
        } else {
          const newCurrentTag = tags.find((t) => {
            return t.slug === tagSlug;
          });
  
          setCurrentTag(newCurrentTag);
        }
      }

      if ((!files && currentTag) || routeChanged) {
        const filesApiUrl = apiUrls.files.paginatedList(currentTag.id.toString());
        
        if (loadingFilesApiUrl !== filesApiUrl) {

          setIsLoadingNextPage(true);
          setLoadingFilesApiUrl(filesApiUrl);

          if (routeChanged) {
            setFiles(null);  
          }

          axios.get(filesApiUrl)
            .then((res) => {
              const files = res.data?.results;
              const nextPageApiUrl = res.data?.next;

              setFiles(files);
              setIsLoadingNextPage(false);
              setNextFilePageApiUrl(nextPageApiUrl);
            })
            .catch((err) => {
              console.log('Error: loading files data', err);
            });
        }
        
      }
    };
  }, [system, tags, files, currentTag, tagSlug, routeChanged, loadingFilesApiUrl, setLoadingFilesApiUrl]);

  const processPagination = useCallback(() => {
    // Don't react on scroll if already fetching data.
    // if (getNewsRequest || getMoreNewsRequest || isNoMoreNews) {
    if (isLoadingNextPage || !nextFilePageApiUrl) {
      return false;
    }

    const scrolledPercentage = (100 * window.pageYOffset) / (document.body.scrollHeight - window.innerHeight);

    if (scrolledPercentage >= 70) {
        axios.get(nextFilePageApiUrl)
          .then((res) => {
            const newPageFiles = res.data?.results;
            const nextPageApiUrl = res.data?.next;
            // const files = res.data;
            
            const newFiles = files.concat(newPageFiles);

            setFiles(newFiles);
            setNextFilePageApiUrl(nextPageApiUrl);
          })
          .catch((err) => {
            console.log('Error: loading file page data', err);
          });
    }

    return null;
  }, [isLoadingNextPage, nextFilePageApiUrl]);

  const scrollDeps = [
    isLoadingNextPage,
    nextFilePageApiUrl,
  ];

  useScrollPosition(processPagination, scrollDeps, null, true, 100);

  return (
    <S.Container
      onMouseMove={() => {
        if (!isCustomCursorEnabled && isRenderedTimeout) {
          customCursorController.enable()
          // .hideTrueCursor();
          setIsCustomCursorEnabled(true);
        }
      }}
    >
      {!isLoading && <>
        {files && <FilesGrid
          customCursorController={customCursorController}
          isMobileViewport={isMobileViewport} 
          system={system}
          files={files}
          setCurrentFile={setCurrentFile}
          setCurrentlyHoveredFile={setCurrentlyHoveredFile}
          resetIsScrolling={resetIsScrolling}
          customColumnsCount={customColumnsCount}
          setCustomColumnsCount={setCustomColumnsCount}
        />} 
      </>}
    </S.Container>
  )
};

export default TagIndex;
