import styled from 'styled-components';

import { aspectRatio } from '../../common/styles/mixins';
import media from '../../common/styles/media';

const S = {};

S.Container = styled.div`
  ${aspectRatio(1, 1)};
  position: relative;
  width: 100%;
`;

S.MarginsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media ${media.mobile} {
    padding: 0 !important;
  }
`;

S.MarginsInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

S.Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${media.mobile} {
    padding: 35px !important;
  }
`;

S.Preview = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    // cursor: default;

    ${({ isAudio }) => isAudio && `
      display: block;
      width: 100%;
      height: 100%;
    `};

    &:hover, &:active {
      // cursor: default;

      ${({ isScrolling, isAudio }) => (isAudio && !isScrolling) && `
        // cursor: url('img/cursor.svg') 23 23, pointer;
	      // cursor: -webkit-image-set(url('img/cursor@2x.svg') 2x) 23 23, pointer;
      `};
    }
  }

  img {
    user-select: none;

    &:hover, &:active {
      ${({ isScrolling }) => !isScrolling && `
        // cursor: url('img/cursor.svg') 23 23, pointer;
	      // cursor: -webkit-image-set(url('img/cursor@2x.svg') 2x) 23 23, pointer;
      `};
    }
  }
`;

S.AudioTrackIcon = styled.div`
  
`;

S.AudioTrackIconMobile = styled.div`
  display: none;
`;

S.AudioTrack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 13.8%; // 50px
  pointer-events: none;

  @media ${media.mobile} {
    padding: 0 20px;

    ${S.AudioTrackIcon} {
      display: none;
    }

    ${S.AudioTrackIconMobile} {
      display: block;
    }
  }

  svg {
    width: 100%;
    fill: var(--colorText);
  }
`;

S.Duration = styled.div`
  position: absolute;
  bottom: 13.6%; // 49px
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  pointer-events: none;
  color: var(--colorText);
  user-select: none;

  ${({ isVideo }) => isVideo && `
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--backgroundLight);
  `};

  @media ${media.mobile} {
    bottom: 20px;
    line-height: 25px;

    ${({ isVideo }) => isVideo && `
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      color: var(--backgroundLight);
    `};
  }
`;

S.Tags = styled.div`
  display: flex;
`;

S.Tag = styled.div`
  padding: 0 4px;
  color: var(--colorCaption);
  background: var(--background);    
`;

S.Info = styled.div`
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  pointer-events: none;

  ${({ isShow }) => isShow && `
    visibility: visible;  
  `};

  ${({ isClipped }) => isClipped && `
    align-items: flex-end;
    transform: translateX(-100%);
  `};

  @media ${media.mobile} {
    display: none;
  }
`;

S.Name = styled.div`
  display: inline-block;
  padding: 0 4px;
  color: var(--colorText);
  background: var(--background);
  white-space: nowrap;
`;

S.Date = styled.div`
  display: inline-block;
  padding: 0 4px;
  white-space: nowrap;
  color: var(--colorCaption); 
  background: var(--background); 
`;

export default S;
