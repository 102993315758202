import styled from 'styled-components';


const S = {};

S.Container = styled.div`
  
`;

S.Content = styled.div`

`;

export default S;
